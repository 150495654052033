<template>
  <div class="h-full">
    <div class="grid grid-cols-3 gap-6 h-full">
      <div class="font-frank text-active-800 bg-active-50 px-4 py-5">
        <div class="text-xl font-medium mb-3">
          request cycle settings
        </div>
        <div class="text-2sm font-normal">
          You can apply {{ activeCommunity.name }} community settings to other communities via three dots menu
        </div>
      </div>

      <div class="col-span-2 py-2 text-black">
        <FinalForm
            v-if="initialValues"
            class="h-full"
            ref="form"
            :submit="handleSubmit"
            :initial-values="initialValues"
            @change.self="handleFormChange"
        >
          <template v-slot="props">
            <form class="h-full" ref="formElement" @submit="props.handleSubmit">
              <div class="section-title mb-3">granularity of service requests</div>
              <div class="status-schema-field">
                <div
                    v-for="option of statusSchemaOptions"
                    :key="option.key"
                    class="flex flex-col items-baseline text-2sm mr-8"
                >
                  <RadioInput
                      name="statusSchema"
                      :label="option.value"
                      :value="option.key"
                      :disabled="!canEdit"
                      class="font-frank font-normal"
                      :class="{'text-active-500': formValues.statusSchema === option.key}"
                  />
                  <div class="flex flex-col ml-6">
                    <div v-for="(status, index) in option.statuses" :key="index">
                      - {{ status }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                  v-if="disableAutocompleteSettings && hasWaitingRequests"
                  class="waiting-autocomplete-infobox"
              >
                <ExclamationCircleIcon class="icon"/>
                <div>to requests created under advanced scheme settings bellow will apply</div>
              </div>

              <div
                  v-if="!(initialValues.statusSchema === STATUS_SCHEMA_BASIC && formValues.statusSchema === STATUS_SCHEMA_BASIC)"
                  class="autocomplete-settings"
                  :class="{'disabled': disableAutocompleteSettings}"
              >
                <div class="section-title mb-2">
                  resident confirmation time
                </div>
                <div class="font-frank font-normal text-2sm mb-3">
                  The time during which a resident can approve or decline a request
                </div>

                <div class="flex mb-6">
                  <div class="mr-8">
                    <div class="text-xs font-semibold mb-2">
                      number of days*
                    </div>
                    <CounterField
                        name="residentWaitingAutoCompleteTimeDays"
                        helper-text="min 1"
                        :disabled="!canEdit || disableAutocompleteSettings"
                        :min="1"
                        :max="365"
                    />
                  </div>

                  <div>
                    <div class="text-xs font-semibold mb-2">
                      count weekends?
                    </div>

                    <div class="toggle-field-wrapper">
                      <ToggleField
                          name="residentWaitingAutoCompleteCountWeekend"
                          :label="formValues.residentWaitingAutoCompleteCountWeekend ? 'yes' : 'no'"
                          :disabled="!canEdit || disableAutocompleteSettings"
                          size="extra-large"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div class="text-xs font-semibold mb-4">
                    close automatically once confirmation time expires:*
                  </div>

                  <div class="toggle-field-wrapper">
                    <ToggleField
                        name="residentWaitingAutoCompleteBySystem"
                        :label="formValues.residentWaitingAutoCompleteBySystem ? 'yes' : 'no'"
                        :disabled="!canEdit || disableAutocompleteSettings"
                        size="extra-large"
                    />
                  </div>
                </div>
              </div>
            </form>
          </template>
        </FinalForm>
      </div>
    </div>

    <modal-footer
        :footer="{primaryButton: 'save', tertiaryButton: 'cancel'}"
        :primary="requestSubmit"
        :tertiary="close"
    />
    <loader :loading="loading" :backdrop="true"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import Loader from "@/components/ui/Loader";
import ModalNavigation from "@/mixins/ModalNavigation";
import ConfirmationMixin from "@/mixins/ConfirmationMixin";
import NotifyMixin from "@/mixins/NotifyMixin";
import SreqMixin from "@/mixins/SreqMixin";
import {FinalForm} from 'vue-final-form';
import {STATUS_RESIDENT_VALIDATION, STATUS_SCHEMA_ADVANCED, STATUS_SCHEMA_BASIC} from "@/views/sreq/requests/constants";
import RadioInput from "@/components/form/RadioInput";
import {isEqual} from "lodash-es";
import ToggleField from "@/components/form/ToggleField";
import ExclamationCircleIcon from "@/components/ui/icons/ExclamationCircleIcon";
import CounterField from "@/components/form/CounterField";

export default {
  components: {
    CounterField,
    ExclamationCircleIcon, ToggleField, RadioInput, Loader, ModalFooter, FinalForm},

  mixins: [ModalNavigation, ConfirmationMixin, NotifyMixin, SreqMixin],

  data() {
    return {
      STATUS_SCHEMA_BASIC,
      STATUS_SCHEMA_ADVANCED,

      // TODO: consider to get it from the backend
      statusSchemaOptions: [
        {
          key: STATUS_SCHEMA_BASIC,
          value: 'basic scheme',
          statuses: [
            'Received',
            'In Progress',
            'Completed',
            'Canceled',
          ],
        },
        {
          key: STATUS_SCHEMA_ADVANCED,
          value: 'advanced scheme',
          statuses: [
            'Received',
            'In Progress',
            'Waiting Manager validation',
            'Waiting Resident validation',
            'Completed',
            'Canceled',
          ],
        },
      ],
      loading: false,
      initialValues: undefined,
      formValues: undefined,
      hasWaitingRequests: false,
    };
  },

  computed: {
    ...mapGetters({
      activeCommunity: 'auth/community',
    }),

    canEdit() {
      return this.hasSreqPermission('CSM');
    },

    hasChanges() {
      return !isEqual(this.initialValues, this.formValues);
    },

    disableAutocompleteSettings() {
      return this.initialValues?.statusSchema === STATUS_SCHEMA_ADVANCED && this.formValues?.statusSchema === STATUS_SCHEMA_BASIC;
    },
  },

  methods: {
    async requestSubmit() {
      if (!this.hasChanges) {
        this.notifySuccess('Settings successfully saved');
        return;
      }

      if (this.hasChanges && await this.requestConfirmation({
        confirmationMessage: 'Are you sure you want to change settings?',
        confirmBtnText: 'Change',
        cancelBtnText: 'Cancel',
      })) {
        this.$refs.formElement.requestSubmit();
      }
    },

    handleFormChange(state) {
      this.formValues = state.values;
    },

    handleSubmit(values) {
      this.loading = true;
      this.$sreqDataProvider.update('communitySettings', {data: values})
          .then(() => {
            this.initialValues = values;

            this.notifySuccess('Settings successfully saved');
          })
          .catch((error) => {
            this.notifyError(error.message);
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },

  mounted() {
    this.loading = true;
    Promise.all([
      this.$sreqDataProvider.getOne('communitySettings').then(response => {
        this.formValues = this.initialValues = response;
      }),
      this.$sreqDataProvider.countByState('serviceRequests', {state: STATUS_RESIDENT_VALIDATION}).then(response => {
        this.hasWaitingRequests = response > 0;
      })
    ]).catch(error => {
      this.notifyError(error.message);
    }).finally(() => {
      this.loading = false;
    });
  },

  async beforeRouteLeave() {
    if (this.hasChanges) {
      return await this.requestConfirmation({
        confirmationType: 'warning',
        confirmationMessage: 'are you sure you want to leave without saving changes?',
        confirmBtnText: 'yes, leave without saving',
        cancelBtnText: 'no, go back',
      });
    }
  },
}
</script>

<style scoped>
.section-title {
  @apply text-sm font-frank font-bold;
}

.status-schema-field {
  @apply flex mb-8;

  :deep(.form-col) {
    @apply mb-0 flex-grow-0;
  }
}

.toggle-field-wrapper:deep(.form-col) {
  @apply inline-flex flex-row-reverse items-center;

  label:first-child {
    @apply mb-0 ml-2;
  }

  /*.switch__lever {
    transform: scale(1.25);
    transform-origin: 0 0;
    !*@apply h-10;

    &:after {
      @apply h-8;
    }*!
  }*/
}

form {
  :deep(.form-col) {
    @apply mx-0;
    label {
      color: inherit;
    }
  }
}

.autocomplete-settings.disabled {
  filter: grayscale(1);
  opacity: .5;
}

.waiting-autocomplete-infobox {
  @apply flex justify-center border border-active-150 bg-active-50 text-active-800 text-sm font-frank font-medium px-8 py-6 mb-4;

  .icon {
    @apply w-4 h-4 mr-2;
    transform: translateY(3px);
  }
}
</style>
