<template>
  <div class="h-full">
    <div class="grid grid-cols-5 gap-8 h-full">
      <div class="col-span-3 flex flex-col h-full overflow-auto">
        <div class="flex items-end justify-between mb-3">
          <div class="flex-grow text-black text-sm font-frank font-bold">
            select communities to apply the settings of {{ activeCommunity.name }}
          </div>
          <div class="flex">
            <button
                class="btn-primary btn-transparent mr-4"
                @click="selectAll"
                :disabled="selectedIds.length === communities.length"
            >
              all
            </button>
            <button
                class="btn-primary btn-transparent"
                @click="deselectAll"
                :disabled="selectedIds.length === 0"
            >
              none
            </button>
          </div>
        </div>

        <div class="mb-4">
          <TextInput
              placeholder="Search by community"
              clearable
              v-model="searchQuery"
              @clear="searchQuery = ''"
          >
            <template v-slot:icon>
              <SearchIcon class="w-4 h-4 text-graphite-800"/>
            </template>
          </TextInput>
        </div>
        
        <div class="flex-grow overflow-auto">
          <div
              v-for="community in communities"
              :key="community.id"
              class="font-frank font-normal text-2sm text-black mb-2"
          >
            <label v-show="isVisible(community)">
              <input
                  type="checkbox"
                  v-model="selectedIds"
                  :value="community.id"
                  class="mr-2"
              >
              <TextHighlighter :text="community.name" :query="searchQuery"/>
            </label>
          </div>
        </div>
      </div>

      <div class="col-span-2 bg-graphite-200 p-4 flex flex-col h-full overflow-auto">
        <div class="text-black text-sm font-frank font-bold mb-4">
          {{ selectedIds.length }} {{ selectedIds.length ? `from ${communities.length}` : '' }} communities selected
        </div>

        <div class="flex flex-wrap overflow-auto">
          <Tag
              v-for="community in selectedCommunities"
              :key="community.id"
              :text="community.name"
              class="mb-2 mr-2"
              deletable
              @click="deselectOne(community.id)"
          />
        </div>
      </div>
    </div>

    <modal-footer
        v-show="selectedIds.length !== 0"
        :footer="{primaryButton: 'apply', tertiaryButton: 'cancel'}"
        :primary="requestSubmit"
        :tertiary="close"
    />
    <loader :loading="loading" :backdrop="true"/>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import Loader from "@/components/ui/Loader";
import ModalFooterMixin from "@/components/ui/modals/ModalFooterMixin";
import TextInput from "@/components/ui/TextInput";
import SearchIcon from "@/components/ui/icons/SearchIcon";
import TextHighlighter from "@/components/ui/TextHighlighter";
import Tag from "@/components/ui/Tag";
import ConfirmationMixin from "@/mixins/ConfirmationMixin";
import NotifyMixin from "@/mixins/NotifyMixin";
import {mapGetters} from "vuex";

export default {
  components: {Tag, TextHighlighter, SearchIcon, TextInput, Loader, ModalFooter},

  mixins: [ModalNavigation, ModalFooterMixin, ConfirmationMixin, NotifyMixin],

  data() {
    return {
      loading: false,
      communities: [],
      searchQuery: '',
      selectedIds: [],
    };
  },

  computed: {
    ...mapGetters({
      activeCommunity: 'auth/community',
    }),

    selectedCommunities() {
      return this.communities.filter(({id}) => this.selectedIds.find(selectedId => selectedId === id));
    },
  },

  methods: {
    selectAll() {
      this.selectedIds = this.communities.map(({id}) => id);
    },

    deselectAll() {
      this.selectedIds = [];
    },

    deselectOne(id) {
      this.selectedIds = this.selectedIds.filter(selectedId => selectedId !== id);
    },

    isVisible(community) {
      return community.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    },

    async requestSubmit() {
      if (await this.requestConfirmation({
        confirmationMessage: `are you sure you want to apply ${this.activeCommunity.name} community settings to ${this.selectedIds.length} selected communities?`,
        confirmBtnText: 'yes, apply',
        cancelBtnText: 'no, go back',
      })) {
        const payload = this.selectedIds.length === this.communities.length ?
            {allCommunities: true} :
            {communityIds: this.selectedIds};

        this.loading = true;
        this.$sreqDataProvider.applyTo('communitySettings', {
          data: payload,
        }).then(() => {
          this.notifySuccess(`applied settings to ${this.selectedIds.length} communities`);
        }).catch(error => {
          this.notifyError(error.message);
        }).finally(() => {
          this.loading = false;
        });
      }
    },
  },

  mounted() {
    this.loading = true;

    this.$sreqDataProvider.getList('communities').then((communities) => {
      this.communities = communities.map(({communityId, name}) => ({id: communityId, name}));
    }).catch(error => {
      this.notifyError(error.message);
    }).finally(() => {
      this.loading = false;
    });
  }
}
</script>

<style scoped>
.btn-primary.btn-transparent {
  @apply text-2xs;
}

input[type=checkbox] {
  transform: translateY(1px);
}
</style>
